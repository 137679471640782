
<template>
    <div class="splash" v-if="isSplashActive">
        <img src="@/assets/splash.png" />
    </div>    
</template>

<script>
import { eventBus } from "@/eventBus";
export default {
    data () {
        return {
            isSplashActive: true
        }
    },
    mounted () {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";

        if (sessionStorage.getItem("splash-enabled")) {
            document.getElementsByTagName("body")[0].style.overflow = "auto";
            this.isSplashActive = false;
        }
        eventBus.$on("featured-loaded", () => {
            document.getElementsByTagName("body")[0].style.overflow = "auto";

            sessionStorage.setItem("splash-enabled", true);
            this.isSplashActive = false;
        });
        setTimeout(() => {
            document.getElementsByTagName("body")[0].style.overflow = "auto";
            sessionStorage.setItem("splash-enabled", true);
            this.isSplashActive = false;
        }, 6000)
        
    }
}
</script>

<style lang="scss" scoped>
    .splash {
        width: 100vw;
        height: 100vh;
        background: #282828;
         z-index: 999999999;
        display: block;
        position: relative;
    
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0.4);
        }
    }
    @media screen and (max-width: 767px) {
  .splash {
    img {
        width: 100vw;
        // height: 100vh;
    }
  }
}
</style>